/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import Header from "./Header";
import Side from "./Side";
import Menu from "./Menu";
import {whiteTheme, darkTheme} from "../data/theme.js"
import "./styles/layout.css"
import { motion } from "framer-motion"

const Layout = ({ children, theme = "white", doAnimate=true}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  var themeStyle = theme == "white" ? whiteTheme : darkTheme

  return (
    <Container id="outer-container" theme={theme}>
      
      <div id="root">
      
      
        <main id="page-wrap">
        
        <Menu theme={themeStyle}/>
          
        <Side theme={themeStyle}/>
        <Header  theme={themeStyle} siteTitle={data.site.siteMetadata?.title || `Title`} />    
        <div id="container">
        <AnimationContainer doAnimate={doAnimate}>
          {children}
        </AnimationContainer>
        </div>
        </main>
      </div>
    </Container>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;


const AnimationContainer = ({doAnimate, children}) => {
  if (doAnimate) {
    return(
      <motion.main
      initial={{ opacity: 0, x: -200 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 200 }}
      transition={{
        type: "spring",
        mass: 0.35,
        stiffness: 75,
        duration: 0.3
      }}
    >
      {children}
    </motion.main>
    )
  }

  return(
    <div>
      {children}
    </div>
  )
}

const Container = styled.div`
  background: ${props => props.theme.primary};
  --background-color: #eeeeee;
`
