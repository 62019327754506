import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components"
import {FiGithub, FiLinkedin, FiInstagram} from "react-icons/fi"
import {HiOutlineMail} from "react-icons/hi"

const Side = ({theme}) => {

    return (<StyledSideContainer data-sal="fade" data-sal-delay="800" data-sal-easing="easeInQuart">
        <DivWithBorder/>
        <LogoWrapper theme={theme}>
            <StyledIcon theme={theme} icon={FiLinkedin} url="https://www.linkedin.com/in/felipe-angelim/" label="Linkedin"/>
            <StyledIcon theme={theme} icon={FiGithub} url="https://github.com/felipeangelimvieira" label="Github"/>
            <StyledIcon theme={theme} icon={HiOutlineMail} url="mailto:felipeangelim@gmail.com?subject=Hi, let's get in touch!" label="Email"/>
        </LogoWrapper>
        
        
    </StyledSideContainer>)

};


const StyledIcon = ({icon, url, label, theme}) => {

    const StyledElement = styled(icon)`
    z-index: 30;
    width: 20px;
    color: ${props => props.theme.sideIcon};
    
    transition-duration: 0.3s;

    &:hover,
      &:focus {
        transform: translateY(-3px);
        color: #00EBA3;
      }

    `

    return  <StyledLink to={url} aria-label={label} target="_blank" >
    <StyledElement theme={theme}/>
</StyledLink>


}

const LogoWrapper = ({children, theme}) => {

    const wrappedChildren = children.map((child, index) => {
        return(
            
            <StyledLogoContainer theme={theme} background="#171C28" zIndex="22" key={index}>
            {child}
            </StyledLogoContainer>
            
        )
    })

    return (
        <LogosDiv display="flex" >
            {wrappedChildren}
        </LogosDiv>
        
    )
}


const StyledLink = styled(Link)`
    text-decoration: none;

    color: rgba(255, 255, 255, .7);
    
    transition-duration: 0.3s;

    &:hover,
      &:focus {
        transform: translateY(-3px);
        color: #00EBA3;
      }

`

const LogosDiv = styled.div`
    display: flex;
    flex-direction: column;

`

const StyledLogoContainer = styled.div`
    background: ${props => props.theme.sideBackground};
    display: flex;
    align-items: center;
    padding-bottom: 8px;
    padding-top: 8px;
    margin-bottom: 12px;
    margin-top: 12px;
    z-index: 4;

`

const DivWithBorder = styled.div`
    left : calc(50% - 0.05px);
    position: absolute;
    background: transparent;
    height: 40%;
    border-left: 1px solid rgba(238, 238, 238, .0);
    z-index: -1;
`


const StyledSideContainer = styled.div`
  background: transparent;
  display: flex;
  width: 40px;
  height: 100vh;
  position: fixed;
  align-items: center;
  justify-content: center;
  right: 2vw;
  z-index: 10;
  @media (max-width: 768px) {
    display: none;
  }
`


export default Side;