import React from "react"
import {Link} from "gatsby"
import styled from "styled-components";
import { menuData } from "../../data/MenuData"
import { slide as BurgerMenu } from 'react-burger-menu';

export default ({theme}) => {
    return <BurgerMenuWrapper theme={theme}>
      <BurgerMenu right   pageWrapId={ "page-wrap" }   outerContainerId={ "outer-container" }>
        {menuData.map((item, index) => (
          <StyledLink to={item.link} key={index}>
            {item.title}
          </StyledLink>
        ))}
      </BurgerMenu>
      </BurgerMenuWrapper>
}

const BurgerMenuWrapper = styled.div`
   z-index: 100;
   display: none;
   @media screen and (max-width: 768px) {
      display: block;
   }


   /* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 28px;
  z-index: 100;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #0c0c0c;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: rgba(255, 255, 255, 0.9);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: ${props => props.theme.burgerMenu};
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  z-index:101;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #00EBA3;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

`

const StyledLink = styled(Link)`
    text-decoration: none;
    color: inherit;
    color: #fff;
  `
