import logo from "../assets/logo.svg";
import logoWhite from "../assets/logo_white.svg";

export const darkTheme = {

    "blue"  : "#171C28",
    "primary" : "#171C28",
    "secondary" : "#fff",
    "highlight" : "#00EBA3",
    "navbar" : "transparent",
    "logo" : logo,
    "burgerMenu" : "#2A3348",
    "sideIcon" : "rgba(255, 255, 255, 0.7)",
    "sideBorder" : "rgba(255, 255, 255, 0.7)",
    "sideBackground" : "#171C28",
    "navbar" : "#fcfcfc"

}

export const whiteTheme = {

    "blue"  : "#171C28",
    "primary" : "#eeeeee",
    "secondary" : "#171C28",
    "highlight" : "#00EBA3",
    "navbar" : "#171C28",
    "logo" : logo,
    "burgerMenu" : "#0c0c0c",
    "sideIcon" : "rgba(0, 0, 0, 0.7)",
    "sideBorder" : "transparent",
    "sideBackground" : "transparent",
    "navbar" : "#0c0c0c"

     

}