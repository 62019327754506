import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components"
import {FaBars} from "react-icons/fa"
import { menuData } from "../../data/MenuData"
import { Button } from "../Button"
import logo from "../../assets/logo.svg";
import logoWhite from "../../assets/logo_white.svg";
import { slide as Menu } from 'react-burger-menu';
//import AniLink from "gatsby-plugin-transition-link/AniLink";

const useState = React.useState;

const Header = ({ theme }) => {

  const [menu, showMenu] = useState(true);

  return (
    <Nav theme={theme}>
      <LogoLink to="/">
        
      </LogoLink>
      
      <NavMenu>
        {menuData.map((item, index) => (
          <NavLink paintDrip to={item.link} key={index}>
            {item.title}
          </NavLink>
        ))}
      </NavMenu>
    </Nav>
  )
};

export default Header;


const Nav = styled.nav`
 position: sticky;
 top: 0;
 height: 80px;
 display: flex;
 justify-content: space-between;
 padding: 0.6rem calc((90vw - 1300px) / 2);
 z-index: 100;
 font-family: "Inconsolata";
 backdrop-filter: blur(1px);
 background: linear-gradient(180deg, #c9c9c9 0%,  transparent 100%, transparent 100%)


 `

const LogoLink = styled(Link)`
  color: #fff;
  display: inline-block;
  text-decoration: none;
  padding: 0 1rem;
  height: 50%;
  transition: all 0.01s ease 0s, left 0.01s ease 0s;
  position: relative;
  margin-top: 2vh;
  transition: 1s;


  &:hover {

    transform: rotate(-20deg);
  }
`

const NavLink = styled(Link)`
  text-transform: uppercase;
  color: #0c0c0c;
  display: inline-block;
  text-decoration: none;
  padding: 0 1rem;
  height: 50%;
  transition: all 0.01s ease 0s, left 0.01s ease 0s;
  position: relative;
  margin-top: 2vh;

  &:hover, &:focus {
    color: #00EBA3;
  }

    &:before,
    &:after {
    content: "";
    position: absolute;
    bottom: -2px;
    width: 0px;
    height: 1px;
    margin: 5px 0 0;
    transition: all 0.01s ease-in-out;;
    transition-duration: 0.75s;
    opacity: 0;
    background-color: #00EBA3;
  
  }

  &:before {
    left: 50%;
  }

  &:after {
    right: 50%;
  }
  
  &:hover {
      cursor: pointer;
      
        &:before,
        &:after {
          width: 50%;
          opacity: 1;
        }
      
    }
  
`


const Logo = styled.img`
  margin-bottom: 0;
  width: 60%;
  margin-left: 10px;

`

const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: 48px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`
